/**
 * Standard avatar style
 */
@import (reference) '_util';

@hf-avatar-size: 100%;

.hf-avatar {
	&-preview {
		padding:0 10px;
		.inlineBlockVerticalTop();
	}

	&-caption {
		.inlineBlockVerticalTop();
	}

	&-image {
		position: absolute;
		background-size: @hf-avatar-size @hf-avatar-size;
	}

	&-blur {
		.box-sizing();
		position: absolute;
		width:@hf-avatar-size;
		height:@hf-avatar-size;
		border: 0 solid rgba(0, 0, 0, 0.5);
	}

	&-selector {
		position: absolute;
	}
}